import React, { FC } from "react";

export const About:FC = () => (
  <section id="about" className="about">
    <div className="container">

      <div className="row pt-5">
        <div className="col-lg-6" data-aos="zoom-in">
          <img src="assets/img/about.jpg" className="img-fluid" alt="" />
        </div>
        <div className="col-lg-6 d-flex flex-column justify-contents-center" data-aos="fade-left">
          <div className="content pt-4 pt-lg-0">
            <h3>{process.env.REACT_APP_NAME}: Data-Driven App Success.</h3>
            <p className="fst-italic">
            </p>
            {/* <ul>
              <li><i className="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
              <li><i className="bi bi-check-circle"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
              <li><i className="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperd</li>
            </ul> */}
            <p className="fs-5">
              {process.env.REACT_APP_NAME} is a leading performance agency harnessing the power of technology to drive mobile app success. Our expertise lies in crafting customized advertising solutions that leverage cutting-edge technology to optimize app visibility, attract quality installs, and generate valuable user actions. With our transparent and collaborative approach, we deliver measurable results and fuel long-term success. Partner with {process.env.REACT_APP_NAME} for app growth fueled by innovative technology.
            </p>
          </div>
        </div>
      </div>

    </div>
  </section>
)

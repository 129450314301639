import React, { FC, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { 
  Header, 
  Footer, 
  ScrollToTop, 
  About, 
  CallToAction, 
  Hero, 
  Services, 
  Testimonials, 
  Team, 
  Contact, 
  IntegratedPartners 
} from "./components";


export const App:FC = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    });
    document.title = process.env.REACT_APP_NAME || 'Gradientt';
  }, [])


  return (
  <>
    <Header />
    <Hero />
    <main id="main">
      {/* <div id="about" style={{position: "absolute", top: "-50px", left: 0}}></div> */}
  
      <About />
      {/* <Features /> */}
      <Services />
      <CallToAction />
      <Testimonials />
      <Team />
      {/* <Clients /> */}
      <IntegratedPartners />
      <Contact />
    </main>
    <Footer />

    <ScrollToTop />
  </>
)}
import React, { FC, useEffect, useState } from "react";

export const ScrollToTop:FC = () => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const onScroll = () => setActive(window.scrollY > 100)
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [])

  return (
  <a href="#"
    className={`back-to-top d-flex align-items-center justify-content-center ${active && 'active'}`}
    onClick={() => window.scrollTo(0, 0)}
  >
    <i className="bi bi-arrow-up-short"></i>
  </a>

)}

import React, { FC } from "react"

type Props = {
  src?: string,
  className?: string,
}
const defaultSrc = "https://www.linkedin.com/company/gradientt";
const isGradientt = process.env.REACT_APP_NAME === 'Gradientt'


export const LinkedIn:FC<Props> = ({src, className}) => isGradientt ? (
  <a href={src ? src : defaultSrc} target="_blank" className={`linkedin ${className}`}>
    <i className="bi bi-linkedin"></i>
  </a>
) : <></>

import React, { FC } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css';
// import 'swiper/modules/navigation/navigation.min.css';
// import 'swiper/modules/scrollbar/scrollbar.min.css';

const testimonials = [{
  name: "Saul Goodman",
  title: "CEO & Founder",
  testimony: `Working with ${process.env.REACT_APP_NAME} has been a game-changer for our app's growth. Their data-driven approach and expertise in mobile advertising have delivered outstanding results. Our user acquisition has skyrocketed, and our revenue has seen a significant boost. Their team's dedication, transparency, and strategic insights have truly elevated our app's success.`,
},{
  name: "Sara Wilsson",
  title: "CMO",
  testimony: `${process.env.REACT_APP_NAME}'s performance agency services have exceeded our expectations. Their ability to precisely target our desired audience and optimize campaigns for maximum ROI is impressive. We've seen a substantial increase in app installs and conversions, which has directly impacted our business growth. They are a trusted partner, and I highly recommend their services.`
},{
  name: "Jena Karlis",
  title: "Marketing Director",
  testimony: `The collaboration with ${process.env.REACT_APP_NAME} has been instrumental in our app's success. Their deep understanding of mobile advertising trends and technologies has allowed us to stay ahead of the competition. Their attention to detail, creative ad formats, and continuous campaign optimization have significantly improved our app's visibility, engagement, and user retention. It's been a pleasure working with their talented team.`
},{
  name: "Matt Brandon",
  title: "CFO",
  testimony: `${process.env.REACT_APP_NAME}'s mobile performance agency services have proved to be a smart investment for our app's marketing efforts. Their data-driven approach, coupled with their focus on ROI, has given us a clear picture of our campaign's financial impact. With their help, we have been able to optimize our ad spend, reduce customer acquisition costs, and drive substantial revenue growth. I highly recommend their services to any CFO looking to maximize their app's profitability.`
},{
  name: "John Larson",
  title: "Marketing Manager",
  testimony: `${process.env.REACT_APP_NAME} has been a trusted partner in our app marketing journey. Their expertise in app store optimization and targeted user acquisition has been invaluable. With their guidance, our app has experienced a surge in organic downloads, improved visibility in app stores, and higher user engagement. Their team's professionalism, prompt communication, and commitment to results make them an exceptional mobile performance agency.`
}]

export const Testimonials:FC = () => (
<section id="testimonials" className="testimonials">
  <div className="container pt-5">
    <div className="section-title" data-aos="fade-up">
      <h2>Testimonials</h2>
      <p>
      Our clients rave about the outstanding results we achieve at {process.env.REACT_APP_NAME}. With our data-driven strategies, mobile advertising expertise, and ROI optimization, we fuel remarkable growth in user acquisition, revenue, and overall app success. The trust and recommendations we receive showcase our transparent, collaborative approach. Choose {process.env.REACT_APP_NAME} for exceptional mobile performance agency services and unlock unparalleled app success.
      </p>
    </div>    
    <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
      <Swiper 
        modules={[Pagination]}
        speed={600}
        loop={true}
        autoplay={{delay: 5000, disableOnInteraction: false}}
        slidesPerView={3}
        pagination={{clickable: true}}
        breakpoints={{
          320: {slidesPerView: 1, spaceBetween: 20},
          1200: {slidesPerView: 3, spaceBetween: 20}
        }}
      >
        {testimonials.map((t,i) => (
        <SwiperSlide key={i}>
          <div className="testimonial-item">
            <p>
              <i className="bx bxs-quote-alt-left quote-icon-left"></i>
              {t.testimony}
              <i className="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
            <img src={`assets/img/testimonials/testimonials-${i+1}.jpg`} className="testimonial-img" alt=""/>
            <h3>{t.name}</h3>
            <h4>{t.title}</h4>
          </div>
        </SwiperSlide>))}
      </Swiper>
    </div>
  </div>
</section>  
)
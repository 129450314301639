import React from 'react';
import {createRoot} from 'react-dom/client';
import { App } from './App';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/style.css';
// import './assets/js/main';

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(<App />);
}
  
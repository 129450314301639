import React, { FC } from "react";

const partners = [
  {
    name: 'AppsFlyer',
    logo: 'media/partners/appsflyer.png',
  },{
    name: 'Adjust',
    logo: 'media/partners/adjust.png',
  },{
    name: 'AppMetrica',
    logo: 'media/partners/appmetrica.png',
  },{
    name: 'Branch',
    logo: 'media/partners/branch.svg',
  },{
    name: 'MyTracker',
    logo: 'media/partners/mytracker.png',
  },{
    name: 'Kochava',
    logo: 'media/partners/kochava.png',
  },{
    name: 'RevenueCat',
    logo: 'media/partners/revenuecat.png',
  },{
    name: 'Tenjin',
    logo: 'media/partners/tenjin.png',
  },
]
export const IntegratedPartners:FC = () => (
  <section id="partners" className="clients">
    <div className="container pt-5">

      <div className="section-title" data-aos="fade-up">
        <h2>Integrated Partners</h2>
        <p>{process.env.REACT_APP_NAME} is seamlessly integrated with all leading MMPs, ensuring accurate and comprehensive tracking, optimization, and reporting for our clients' mobile app campaigns.</p>
      </div>
      <div className="row no-gutters clients-wrap clearfix wow fadeInUp">
        {partners.map((p, i) => (
        <div key={i} className="col-lg-3 col-md-4 col-xs-6 ps-0 pe-1">
          <div className="client-logo" data-aos="zoom-in" data-aos-delay={100 + i * 50}>
            <img src={p.logo} className="img-fluid" alt={p.name} width="auto"/>
          </div>
        </div>))}
      </div>

    </div>
  </section>
)
import React, { FC } from "react";
import { LinkedIn } from "../LinkedIn";

const members = {
  Gradientt: [{
    name: "Boris Koifman",
    title: "Chief Executive Officer",
    linkedin: "https://www.linkedin.com/in/bkoifman/",
    facebook: null,
    twitter: null,
    instagram: null,
    photo: "media/team/boris.jpeg"
  }, {
    name: "Alex Simanovsky",
    title: "Head of Demand",
    linkedin: "https://www.linkedin.com/in/alexander-simanovskiy-a935a620/",
    facebook: null,
    twitter: null,
    instagram: null,
    photo: "media/team/alex.jpeg"
  },{
    name: "Shahar Volshtater",
    title: "Head of Supply",
    linkedin: "https://www.linkedin.com/in/shahar-volshtater-b23a69a8/",
    facebook: null,
    twitter: null,
    instagram: null,
    photo: "media/team/shahar.jpeg"
  }],
  MobArt: [{
    name: "Efim Abramson",
    title: "Chief Executive Officer",
    linkedin: null,
    facebook: null,
    twitter: null,
    instagram: null,
    photo: "media/team/efim.jpeg",
  }, {
    name: "Boris Koifman",
    title: "Head of Strategy",
    linkedin: null,
    facebook: null,
    twitter: null,
    instagram: null,
    photo: "media/team/boris.jpeg"
  },{
    name: "Dor Levy",
    title: "Head of Sales",
    linkedin: null,
    facebook: null,
    twitter: null,
    instagram: null,
    photo: "media/team/dor.jpeg"
  }]
}

const myMembers = process.env.REACT_APP_NAME === 'Gradientt' ? members.Gradientt : members.MobArt;

export const Team:FC = () => (
  <section id="team" className="team">
    <div className="container pt-5">
      <div className="section-title" data-aos="fade-up">
        <h2>Team</h2>
        <p>Our talented team at {process.env.REACT_APP_NAME} comprises experienced mobile advertising experts who are passionate about driving app success and dedicated to delivering exceptional results for our clients.</p>
      </div>
      <div className="row">
        {myMembers.map((m, i) => (
        <div key={i} className="col-lg-4 col-md-6">
          <div className="member" data-aos="zoom-in">            
            <div className="pic">
              {m.photo &&
              <img src={m.photo} className="img-fluid w-100" alt=""/>}
            </div>
            <div className="member-info">
              <h4>{m.name}</h4>
              <span>{m.title}</span>
              <div className="social">
                {m.twitter && <a href=""><i className="bi bi-twitter"></i></a>}
                {m.facebook && <a><i className="bi bi-facebook"></i></a>}
                {m.instagram && <a href=""><i className="bi bi-instagram"></i></a>}
                {m.linkedin && <LinkedIn className="mx-3" src={m.linkedin} />}
              </div>
            </div>
          </div>
        </div>))}
      </div>
    </div>
  </section>
)
import axios from "axios";
import React, { ChangeEvent, FC, FormEvent, MouseEvent, useRef, useState } from "react";
import { emailPattern } from '../../helper';

export const Contact:FC = () => {
  const [params, setParams] = useState({name: '', email: '', subject: '', message: ''});
  const [state, setState] = useState({loading: false, error: null, submitted: false});
  
  const handleChange = (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setParams({...params, [e.target.name]: e.target.value})
  }

  const handleSubmit = (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (!form.checkValidity()) { return; }
    const url = process.env.REACT_APP_API_URL + '/contact';
    setState({...state, loading: true})
    axios.post(url, params)
    .then(({data}) => {
      setState({loading: false, error: null, submitted: true});
    })
    .catch(error => {
      setState({loading: false, error: null, submitted: true});
      // setState({loading: false, error: error.message, submitted: false});
    });
  }

  const disabled = state.loading || state.submitted;
  
  return (
  <section id="contact" className="contact section-bg">
    <div className="container pt-5">

      <div className="section-title" data-aos="fade-up">
        <h2>Contact Us</h2>
      </div>

      <div className="row">

        <div className="col-lg-5 d-flex align-items-stretch" data-aos="fade-right">
          <div className="info">
            <div className="address">
              <i className="bi bi-geo-alt"></i>
              <h4>Address</h4>
              {process.env.REACT_APP_NAME === 'Gradientt' ?
              <p>Ahad Ha'Am st. 9, Tel Aviv-Yafo, 6514424, Israel</p>:
              <p>Al Sayegh Building - Commercial<br/>Port Saeed 125-0<br/>Dubai, UAE</p>}
            </div>

            <div className="email">
              <i className="bi bi-envelope"></i>
              <h4>Email</h4>
              <p>
                {process.env.REACT_APP_NAME === 'Gradientt' ?
                <a href="mailto:info@gradientt.com">info@gradientt.com</a>:
                <a href="mailto:info@mobart.ae">info@mobart.ae</a>}
                {/* <a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="a8c1c6cec7e8cdd0c9c5d8c4cd86cbc7c5">[email&#160;protected]</a> */}
                </p>
            </div>

            {/* <div className="phone">
              <i className="bi bi-phone"></i>
              <h4>Call:</h4>
              <p>+972-54-24353459</p>
            </div> */}

            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe> */}
            {process.env.REACT_APP_NAME === 'Gradientt' ? 
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3381.19142329804!2d34.76703467475081!3d32.06407092008053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4c9cbe9b3d65%3A0xde756930cfc2928c!2sAhad%20Ha&#39;Am%20St%209%2C%20Tel%20Aviv-Jaffa%2C%206514224!5e0!3m2!1sen!2sil!4v1684954191028!5m2!1sen!2sil" 
              // width={300}
              // height={250}
              // style={{border:0}}
              // allowFullScreen={false} 
              // loading="lazy" 
              // referrerPolicy="no-referrer-when-downgrade"
              // frameBorder={0} 
              style={{border:0, width: '100%', height: '360px'}} 
              allowFullScreen={true}
              >
            </iframe>:
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.945908906771!2d55.32119137538418!3d25.272404977662617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5deefa716dfb%3A0x457f02604d9940a!2sAl%20Sayegh%20Building!5e0!3m2!1sen!2sil!4v1693909101673!5m2!1sen!2sil"
              style={{border:0, width: '100%', height: '330px'}} 
              allowFullScreen={true}
            ></iframe>}

          </div>

        </div>

        <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch" data-aos="fade-left">
          <form onSubmit={handleSubmit} method="post" role="form" className="email-form">
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="name" className="form-label">Your Name</label>
                <input type="text" name="name" className="form-control" id="name" required 
                  disabled={disabled} value={params.name} onChange={handleChange} />
              </div>
              <div className="form-group col-md-6 mt-3 mt-md-0">
                <label htmlFor="email" className="form-label">Your Email</label>
                <input type="email" className="form-control" name="email" id="email" required 
                  pattern={emailPattern} disabled={disabled} value={params.email} onChange={handleChange} />
              </div>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="subject" className="form-label">Subject</label>
              <input type="text" className="form-control" name="subject" id="subject" required 
                disabled={disabled} value={params.subject} onChange={handleChange} />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea className="form-control" name="message" rows={10} required
                disabled={disabled} value={params.message} onChange={handleChange}></textarea>
            </div>
            <div className="my-3">
              {state.loading && <div className="loading d-block">Loading</div>}
              {state.error && <div className="error-message d-block">{state.error}</div>}
              {state.submitted && <div className="sent-message d-block">Your message has been sent. Thank you!</div>}
            </div>
            {!state.submitted &&
            <div className="text-center">
              <button type="submit">Send Message</button>
            </div>}
          </form>
        </div>
      </div>
    </div>
  </section>
)}